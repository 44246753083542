/*
 * Copyright (C) 2024 Airfordable, Inc - All Rights Reserved
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useMutation, useQuery } from '@apollo/client';
import { styled, Theme, Typography } from '@material-ui/core';
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import gql from 'graphql-tag';
import React, { useCallback, useMemo, useState } from 'react';
import Select from 'react-select';

import Layout from 'components/partials/Layout';
import { Overflow } from 'components/partials/Overflow';
import {
  Button,
  Col,
  Container,
  Form,
  FormInput,
  useAlert,
} from 'components/UI';
import { Breakpoints } from 'utils/enums';

// Terminal API Query/Mutation
const DELETE_USER_MUTATION = gql`
  mutation DeleteUserByToken(
    $token: String!
    $reason: String!
    $notes: String
  ) {
    deleteUserByToken(token: $token, reason: $reason, notes: $notes)
  }
`;

const USER_DELETION_INFO_QUERY = gql`
  query UserDeletionInfoByToken($token: String!) {
    userDeletionInfoByToken(token: $token) {
      activeBookingCount
      deletionAllowed
      paidUnDepartedBookingCount
    }
  }
`;

interface UserDeletionInfo {
  userDeletionInfoByToken: {
    activeBookingCount: number;
    deletionAllowed: boolean;
    paidUnDepartedBookingCount: number;
  };
}

// #region Styled Components
const DeletionContainer = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(25),
  [theme.breakpoints.up(Breakpoints.Md)]: {
    marginBottom: 0,
  },
}));

const Spacer = styled('div')<Theme, { size?: 'thin' | 'regular' }>(
  ({ size = 'regular' }) => {
    switch (size) {
      case 'thin':
        return { height: '1em' };
      case 'regular':
      default:
        return { height: '2em' };
    }
  }
);

const CenterWrapper = styled('div')(({ theme }) => ({
  '& > div': {
    justifyContent: 'center',
    marginTop: theme.spacing(10),
  },

  margin: `${theme.typography.pxToRem(100)} auto 0`,
  textAlign: 'center',

  [theme.breakpoints.up(Breakpoints.Md)]: {
    maxWidth: theme.typography.pxToRem(600),
  },
}));

const SuccessMessage = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.success.contrastText,
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const ErrorMessage = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.error.contrastText,
  marginTop: theme.spacing(3),
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const SelectWrapper = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

// #endregion Styled Components

export const query = graphql`
  query ConfirmDeletionQuery {
    confirmDeletionYaml {
      metadata {
        title
        description
      }
      content {
        areYouSure
        irreversible
        activeBooking
        activeBookings
        unDepartedBooking
        unDepartedBookings
        error
        errorNotFound
        errorActiveBookings
        reload
        success
        missingInfo {
          title
          body
        }
        form {
          title
          button
          fieldReason {
            label
            placeholder
            options
          }
          fieldAdditionalInfo {
            label
            placeholder
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    confirmDeletionYaml: {
      metadata: MetaData;
      content: {
        areYouSure: string;
        irreversible: string;
        activeBooking: string;
        activeBookings: string;
        unDepartedBooking: string;
        unDepartedBookings: string;
        error: string;
        errorNotFound: string;
        errorActiveBookings: string;
        reload: string;
        success: string;
        form: {
          title: string;
          button: string;
          fieldReason: {
            label: string;
            placeholder: string;
            options: string[];
          };
          fieldAdditionalInfo: {
            label: string;
            placeholder: string;
          };
        };
        missingInfo: {
          title: string;
          body: string;
        };
      };
    };
  };
}

interface ReasonOption {
  name: string;
  id: string;
}

const ConfirmDeletion: React.FC<Props> = ({ data: gqlData }) => {
  const data = gqlData.confirmDeletionYaml;

  const alert = useAlert();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();

  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');

  data.content.form.fieldReason.options;

  const reasonOptions: ReasonOption[] = useMemo(() => {
    return data.content.form.fieldReason.options.map((option, i) => ({
      id: i.toString(),
      name: option,
    }));
  }, [data.content.form.fieldReason.options]);

  const token = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return decodeURIComponent(urlParams.get('token') || '');
  }, [location.search]);

  const {
    data: deletionInfoData,
    error: deletionInfoError,
    loading: deletionInfoLoading,
  } = useQuery<UserDeletionInfo>(USER_DELETION_INFO_QUERY, {
    variables: { token },
  });

  const [deleteAccount, { loading: mutationLoading }] = useMutation(
    DELETE_USER_MUTATION,
    {
      onCompleted: () => {
        setSuccessMessage(data.content.success);
      },
      onError: (error) => {
        if (
          error?.graphQLErrors?.[0]?.extensions?.code ===
          'ERR_ACCOUNT_HAS_ACTIVE_BOOKINGS'
        ) {
          setErrorMessage(data.content.errorActiveBookings);
        } else if (
          error.graphQLErrors?.[0]?.extensions?.code === 'ERR_GENERAL_NOT_FOUND'
        ) {
          setErrorMessage(data.content.errorNotFound);
        } else {
          setErrorMessage(data.content.error);
        }
      },
    }
  );

  const submit = useCallback(async () => {
    const urlParams = new URLSearchParams(location.search);
    const tokenParam = urlParams.get('token');

    if (tokenParam) {
      const token = decodeURIComponent(tokenParam);

      setErrorMessage('');
      await deleteAccount({ variables: { note, reason, token } });
    } else {
      alert.error(data.content.error);
    }
  }, [location.search, deleteAccount, reason, note, alert, data.content.error]);

  const { activeBookingCount, paidUnDepartedBookingCount, deletionAllowed } =
    deletionInfoData?.userDeletionInfoByToken ?? {};

  const activeCountString = useMemo(() => {
    if (activeBookingCount === 1) {
      return data.content.activeBooking;
    }
    return data.content.activeBookings.replace(
      '{{count}}',
      activeBookingCount?.toString() ?? ''
    );
  }, [
    activeBookingCount,
    data.content.activeBooking,
    data.content.activeBookings,
  ]);

  const paidUnDepartedCountString = useMemo(() => {
    if (paidUnDepartedBookingCount === 1) {
      return data.content.unDepartedBooking;
    }
    return data.content.unDepartedBookings.replace(
      '{{count}}',
      paidUnDepartedBookingCount?.toString() ?? ''
    );
  }, [
    paidUnDepartedBookingCount,
    data.content.unDepartedBooking,
    data.content.unDepartedBookings,
  ]);

  if (deletionInfoLoading) {
    return (
      <Layout>
        <CenterWrapper>
          <h2>Loading...</h2>
        </CenterWrapper>
      </Layout>
    );
  }

  if (!token || deletionInfoError) {
    return (
      <Layout>
        <CenterWrapper>
          <Typography variant="h2">{data.content.missingInfo.title}</Typography>
          <Typography>{data.content.missingInfo.body}</Typography>
        </CenterWrapper>
      </Layout>
    );
  }

  // If deletion is not allowed, show a message and a fake button
  if (!deletionAllowed) {
    return (
      <Layout
        title={data.metadata.title}
        description={data.metadata.description}
      >
        <DeletionContainer>
          <Col md={3} />
          <Col md={6} sm={12}>
            <Typography variant="h3">{data.metadata.title}</Typography>
            <Spacer />
            <Typography>{activeCountString}</Typography>
            <Typography>{data.content.reload}</Typography>

            <Spacer size="thin" />

            <Form>
              <Button onClick={() => null} disabled type="button">
                {data.content.form.button}
              </Button>
            </Form>
          </Col>
        </DeletionContainer>
      </Layout>
    );
  }

  return (
    <Overflow>
      <Layout
        title={data.metadata.title}
        description={data.metadata.description}
      >
        <DeletionContainer>
          <Col md={3} />
          <Col md={6} sm={12}>
            <Typography variant="h3">{data.metadata.title}</Typography>
            <Spacer />

            <Typography>{data.content.areYouSure}</Typography>

            <Spacer size="thin" />

            <Typography>{data.content.irreversible}</Typography>

            <Spacer size="thin" />

            {!!paidUnDepartedBookingCount && (
              <Typography>{paidUnDepartedCountString}</Typography>
            )}

            <Spacer size="thin" />

            <Form>
              <Typography variant="h6">{data.content.form.title}</Typography>

              <Spacer size="thin" />

              <SelectWrapper>
                <Select<ReasonOption>
                  onChange={(selected) => setReason(selected?.name ?? '')}
                  options={reasonOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                />
              </SelectWrapper>

              <FormInput
                onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
                  setNote(target.value)
                }
                name="note"
                placeholder={data.content.form.fieldAdditionalInfo.placeholder}
                label={data.content.form.fieldAdditionalInfo.label}
                value={note}
              />

              <Button
                disabled={!token || mutationLoading}
                onClick={submit}
                type="button"
              >
                {mutationLoading ? 'Loading...' : data.content.form.button}
              </Button>

              {successMessage && (
                <SuccessMessage>{successMessage}</SuccessMessage>
              )}
            </Form>
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <Spacer />
          </Col>
        </DeletionContainer>
      </Layout>
    </Overflow>
  );
};

export default ConfirmDeletion;
